import React, { Component, useState, useEffect } from "react";
import "./interact.css";
import { useMemo } from "react";
import InteractAudio from "./components/interactAudio";
import { useDispatch } from "react-redux";
import {
  LexRuntimeV2Client,
  RecognizeTextCommand,
} from "@aws-sdk/client-lex-runtime-v2";
import mic from "../../Images/mic.gif";
import { toast } from "react-toastify";
import {
  postPracticeInteractQuestion,
  getPracticeNodding,
  getPracticeAnswered,
  getPracticeEternaviews,
  practiceQuestionDetail,
} from "../../redux/actions";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import Topics from "./interact-commponents/topics";
import InteractVideoTest from "./components/interactVideoTest";
import { useSelector } from "react-redux";
import RecorderTest from "../../components/RecorderTest";
import moment from "moment";
import { isMobile, isTablet } from "react-device-detect";

const client = new LexRuntimeV2Client({
  region: "us-east-1",
  credentials: {
    accessKeyId: process.env.REACT_APP_LEX_ACCESS_KEYS,
    secretAccessKey: process.env.REACT_APP_LEX_SECRET_KEYS,
  },
});

const PracticeInteractF = (props) => {
  const auth = useSelector((state) => state);
  const [toggleDiv, isDivOpen] = useState({
    isKeyboardOpen: false,
    isTopicOpen: false,
  });
  const [isApiCallSuccess,setisApiCallSuccess] = useState(false)
  const [nodingVideo,setNodingVideo] = useState({})
  const [screenHeight,setscreenHeight] = useState("")
  const [screenWidth,setscreenWidth] =useState(window.screen.width)
  const [detail,setDetail] = useState({screen_mode:"landscape"})
  const [style, manageStyle] = useState({ orientation: "portrait",isPotraitMode:false });
  const [recording, setRecording] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState({});
  const [searchinput, setSearchInput] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoType, setVideoType] = useState("");
  const [orgUrl, setOrgUrl] = useState("");
  const [interact_author, setInteractAuthor] = useState("");
  const [micRecording, setMicRecording] = useState(false);
  const [moduleQuestions, setModuleQuestions] = useState([]);
  const [showHints, setShowHints] = useState(false);
  const [askQuestionBit, setAskQuestionBit] = useState(false);
  const [firstTimePop, setFirstTimePop] = useState(true);
  const [no_transcoding, setNoTranscoding] = useState(false);
  const [practice_detail, setPracticeDetail] = useState({});
  const [maxQuery, setMaxQuery] = useState(false);
  const [blobURL, setBlobURL] = useState("");
  const [showUtterance, setShowUtterance] = useState(false);
  const [utteranceText, setUtteranceText] = useState("");
  let intervalId = null
  const dispatch = useDispatch();

  const manageOrientation = () => {
    let landscape = window.matchMedia("(orientation: landscape)");
    let portrait = window.matchMedia("(orientation: portrait)");
   
    if (portrait.matches === true) {
      manageStyle((prev) => ({ ...prev, orientation: "portrait",isPotraitMode:true  }));
      if (screenWidth) {
        if(parseInt(screenWidth) < 650){
          setscreenHeight((parseInt(screenWidth) * 16) / 9)
        }else{
          const reduceWidth = screenWidth*0.27
          setscreenHeight((parseInt(screenWidth - reduceWidth) * 16) / 9)
        }
        // if(detail == "portrait"){
        //     this.setState({ isPotraitMode: true});
        // }
      }
    } else if (landscape.matches === true) {
      manageStyle((prev) => ({ ...prev, orientation: "landscape",isPotraitMode:false }));
    }
  };

const getPracticingNode = () =>{
  dispatch(
    getPracticeNodding(auth.auth.loginUserToken, (res) => {
      if (res) {
        const { nodding_video, author_id, } = res;
        if(nodding_video){
         
          setOrgUrl(nodding_video);
          // setDetail({screen_mode:res.screen_mode})
          setInteractAuthor(author_id);
          setVideoType("application/x-mpegURL");
          clearInterval(intervalId)
        }
        
      }
    })
  );
}

  useEffect(() => {
    // Fetch practice nodding data

    dispatch(
      getPracticeNodding(auth.auth.loginUserToken, (res) => {
          if (res) {
            const { nodding_video, author_id, } = res;
            setisApiCallSuccess(true)
            if(!nodding_video){
              intervalId = setInterval(getPracticingNode, 3000);
            }else{
              setOrgUrl(nodding_video);
              // setDetail({screen_mode:res.screen_mode})
              setInteractAuthor(author_id);
              setVideoType("application/x-mpegURL");
            }
          }
      })
    );

    // Fetch practice answered data
    dispatch(
      getPracticeAnswered(auth.auth.loginUserToken, (res) => {
        if (res && res.data.length > 0) {
          const questions = res.data.filter((subcat) => !subcat.transition);
          setModuleQuestions(questions);
        }
      })
    );
    dispatch(
      getPracticeEternaviews(auth.auth.loginUserToken, (res) => {
        if (res && !res.error && res.data) {
          setNodingVideo(res.data)
          setDetail(res.data)
          setPracticeDetail(res.data);
          if (res.data.no_of_query === 0) {
            setMaxQuery(true);
          }
        }
      })
    );
    // Fetch practice eternaviews data
   

  }, []);

  const receiveTextFromWhisper= (data) => {
   // $(".text_to_speech").show();
    // console.log(data);
    setShowUtterance(true);
    setUtteranceText( data?.data?.query);
    
  }
  const getTextResponseFromRecorder = (data) => {
    setShowUtterance(true);
    //setUtteranceText(data.key.slice(1, -1));
    sendToGet(data.key.slice(1, -1), data.interpretations, "text", true,data);
  };

  const responseReceive = (response) => {
    if (response.interpretations && response.interpretations.length > 0) {
      sendToGet(response.inputTranscript, response.interpretations, "audio");
    }
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (searchinput !== "") submitQuestion(e);
      else
        toast("Please enter your question.", {
          position: "top-right",
          autoClose: 3000,
          type: "error",
        });
    }
  };

  const submitQuestion = async (e) => {
    e.preventDefault();
    const command = new RecognizeTextCommand({
      botAliasId: "TSTALIASID",
      botId: "RMEHNYXF57",
      localeId: "en_US",
      text: searchinput,
      sessionId: "043758711856848",
    });
    const response = await client.send(command);
    if (response.interpretations && response.interpretations.length > 0) {
      sendToGet(searchinput, response.interpretations, "text");
      isDivOpen((prev) => ({
        ...prev,
        isKeyboardOpen: !toggleDiv.isKeyboardOpen,
      }));
    }
  };

  const sendToGet = (
    question,
    interpretations,
    query_type,
    isAudio = false,
    data
  ) => {
    setisApiCallSuccess(false)
    setRecording(false)
    setAskQuestionBit(true);
    setNoTranscoding(false);
    // setVideoUrl("");
    let formData = new FormData();

    formData.append("question", question);
    formData.append("language", 1);
    formData.append("apikey", "dsDKL4342jertn6438");
    formData.append("type", "practice");
    formData.append("duration", "0");
    formData.append("interact_view_id", practice_detail.id);
    formData.append("interact_user_id", interact_author);
    formData.append("interpretations", JSON.stringify(interpretations));
    formData.append("query_type", isAudio ? "audio" : query_type); // query_type:audio
    formData.append('category_type','practice');
    if(data?.login_uuid){
      formData.append("login_uuid", JSON.stringify(data.login_uuid));
    }
    dispatch(
      postPracticeInteractQuestion(formData, auth.loginUserToken, (res) => {
        setisApiCallSuccess(true)
        setShowHints(false);
        if (res.data && res.data.data.answer.video) {
          setRecording(true);
          setSearchInput("");
          setAskQuestionBit(false);

          if (res.data.data.answer.video.trans_coded_url != null) {
            setDetail(res.data.data.answer.video)
            setVideoUrl(res.data.data.answer.video.trans_coded_url);
            setVideoType("application/x-mpegURL");
              let video = videojs("secondVideo");
              video.play();
          } else {
            setNoTranscoding(true);
          }
        }
      })
    );
  };

  const onInputchange = (event) => {
    const { name, value } = event.target;
    setSearchInput(value);
  };

  const myCallback = () => {
    if(nodingVideo){
      setDetail(nodingVideo)
    }
    setRecording(false);
    setShowUtterance(false);
    setUtteranceText("");
    setNoTranscoding(true);
  };

  const closePopUp = (e) => {
    e.preventDefault();
    setFirstTimePop(false);
  };

  const closeQueryPopUp = (e) => {
    e.preventDefault();
    setMaxQuery(false);
  };

  const toggleTopicsDiv = () => {
    setShowUtterance(false)
    isDivOpen((prev) => ({ ...prev, isTopicOpen: !toggleDiv.isTopicOpen }));
  };
  let var1 = null 
  let testInterval = null
  const questionVideo = (id) => {
    let questionIntervalID = sessionStorage.getItem("questionIntervalID") 
    if(questionIntervalID) clearInterval(questionIntervalID);
      dispatch(
        practiceQuestionDetail(id, auth.auth.loginUserToken, (res) => {
          setNoTranscoding(false);
          setRecording(false);
          setVideoUrl('')
          setShowHints(false);
          if (res.data && res.data.answer.id) {
            let playerIcon = document.getElementById("playerIcon");
            
            if (playerIcon) {
              playerIcon.classList.remove("fa-play");
              playerIcon.classList.add("fa-pause");
            }
  
            setRecordedAudio({});
            setRecording(true);
            setSearchInput("");
  
            let src = "";
            let videoType = "";

          if(var1 < 20) {
            testInterval =  setInterval(() => {
              var1 = var1 +1
            }, 1000);
          }else{
            clearInterval(testInterval)
          }
          // 
            if (res.data.answer.video.trans_coded_url != null) {
              setNoTranscoding(false);
  
              if (res.data.answer) {
                setDetail(res.data.answer.video);
              }
  
              src = res.data.answer.video.trans_coded_url;
              videoType = "application/x-mpegURL";
              setVideoUrl(src);
              setVideoType(videoType);
              let video = videojs("secondVideo");
              video.play();
            } else {
              setNoTranscoding(true);
              // questionVideo(id)
              let newOne  =setInterval(() => {
                  callUntilAnsLoaded(id)
                }, 3000);
              sessionStorage.setItem("questionIntervalID",newOne)
            }
          }
        })
      );
  };

  const callUntilAnsLoaded = (id)=> {
    let questionIntervalID = sessionStorage.getItem("questionIntervalID") 
    setNoTranscoding(true);
    dispatch(
      practiceQuestionDetail(id, auth.auth.loginUserToken, (res) => {
        setShowHints(false);
        if (res.data && res.data.answer.id) {
          let playerIcon = document.getElementById("playerIcon");
          if(playerIcon){
            playerIcon.classList.remove("fa-play");
            playerIcon.classList.add("fa-pause");
          }
          setRecordedAudio({});
          setRecording(true);
          setSearchInput("");

          let src = "";
          let videoType = "";
        
          // res.data.answer.video.trans_coded_url != null
          if (res.data.answer.video.trans_coded_url != null) {
            setNoTranscoding(false);
            if(res.data.answer){
              setDetail(res.data.answer.video)
            }
            src = res.data.answer.video.trans_coded_url;
            videoType = "application/x-mpegURL";
            setVideoUrl(src);
            setVideoType(videoType);
            let video = videojs("secondVideo");
            video.play();
            clearInterval(questionIntervalID);
          } else {
            setNoTranscoding(true);
          }
        }
      })
    );
  };

  const memoizedInteractVideoTest = useMemo(() => (
    <InteractVideoTest
      deviceDetails={{
        deviceOrentation: style.orientation,
        height: screenHeight,
        width: screenWidth,
      }}
      setAudioTranscript={(val) => myCallback()}
      setInteractResponse={(val) => myCallback(val)}
      recording={recording}
      orgUrl={orgUrl}
      videoUrl={videoUrl}
      isApiCallSuccess={isApiCallSuccess}
      videoType={videoType}
      no_transcoding={no_transcoding}
      detail={detail}
    />
  ), [recording, orgUrl, videoUrl, videoType, no_transcoding,style.orientation,detail,isApiCallSuccess]);


  function togglePlayer() {
    let video = videojs("secondVideo");
    let playerIcon = document.getElementById("playerIcon");
    if (video.paused()) {
      video.play();
      playerIcon.classList.remove("fa-play");
      playerIcon.classList.add("fa-pause");
    } else {
      video.pause();
      playerIcon.classList.remove("fa-pause");
      playerIcon.classList.add("fa-play");
    }
  }

  useEffect(() => {
    manageOrientation()
    window.addEventListener("orientationchange", manageOrientation);
    window.addEventListener("resize", manageOrientation);
  }, []);

  return (
    <div>
      <section
        className={
          style.orientation === "portrait"
            ? "interact_screen_section portrait_video_outer_practice"
            : "interact_screen_section"
        }
        ref={(node) => {
          if (node &&  style.orientation === "landscape" && (isMobile || isTablet)) {
        
            let div
            div  = document.getElementById("myVideo");
            if(div?.offsetWidth == 0){
              div =document.getElementById("secondVideo");
            }
            const width = div?.offsetWidth;
            const height = width *9 /16
            if (height) {
              node.style.setProperty(
                "height",
                `${height}px`,
                "important"
              );
            }
          }
          if (node &&  style.orientation === "portrait" && (isMobile || isTablet)) {
              node.style.setProperty(+
                
                "height",
                `auto`,
                "important"
              );
          }
        }}
        // className="interact_screen_section portrait_video_outer"
      >
        <div 
           ref={(node) => {
            if (node &&(isMobile||isTablet)&&  style.orientation === "landscape") {
                const containerWidth = window.screen.height
               const attainWidth =  window.screen.height *16 /9
              if (attainWidth) {
                node.style.setProperty(
                  "max-width",
                  `${attainWidth}px`,
                  "important"
                );
              }
            }
          }}
        className="container">
          {/* {!this.state.noInteract ? ( */}
          <>
            {micRecording == true && (
              <div className="recording-interact">
                <img src={mic} tooltip-position="top" />
              </div>
            )}
            {detail && (detail.views_type == "audio"||detail.type == "audio") && (
                  <InteractAudio
                    setAudioTranscript={(val) => this.hideTranscript()}
                    setInteractResponse={(val) =>
                      this.interactResponseReceive(val)
                    }
                    recording={recording}
                    orgUrl={orgUrl}
                    videoUrl={videoUrl}
                    detail={detail}
                  />
            )}
            
            {detail && (detail.views_type == "video"||detail.type == "video") && (
                 memoizedInteractVideoTest
            )}
          </>
        </div>
        <div className="interact_buttons_div">
          <div className="container interact_btn_container11"
              ref={(node) => {
                if (node &&(isMobile||isTablet)&&style.orientation === "landscape") {
                    // node.style.setProperty(
                    //   "height",
                    //   `${window.screen.height}px`,
                    //   "important"
                    // );
                    const containerWidth = window.screen.height
                   const attainWidth =  window.screen.height *16 /9
                  if (attainWidth) {
                    node.style.setProperty(
                      "max-width",
                      `${attainWidth}px`,
                      "important"
                    );
                  }
                }
              }}
          >
            <div className="row">
              <div className="col-md-12">
                {showUtterance && (
                  <div className="ask_question_popup">
                    <p>{utteranceText}</p>
                  </div>
                )}

                <div className="interact_buttons_inner">
                  {toggleDiv.isKeyboardOpen && (
                    <div id="askquestion" className="ask_question_div">
                      <input
                        name="searchinput"
                        onChange={onInputchange}
                        onKeyDown={_handleKeyDown}
                        type="text"
                        placeholder="Type in your question:"
                      />
                      <div className="ask_btn_div">
                        <a className="topic_btn" onClick={submitQuestion}>
                          Ask Question
                        </a>
                      </div>
                    </div>
                  )}

                  <div className="interact_btn_left">
                    <a
                      onClick={() => {
                        isDivOpen((prev) => ({
                          ...prev,
                          isKeyboardOpen: !toggleDiv.isKeyboardOpen,
                        }));
                      }}
                      style={{cursor:"pointer"}}
                      className="keyboard_icon"
                      id="keyborad"
                    >
                      <i className="far fa-keyboard"></i>
                    </a>
                    <RecorderTest
                      textFromWhisper = {(val)=>{receiveTextFromWhisper(val)}}
                      isPractice={true}
                      setTranscript={(val) => myCallback()}
                      getTextAudio={(val) => getTextResponseFromRecorder(val)}
                      setBotResponse={(val) => responseReceive(val)}
                      client={client}
                      category={""}
                      setBlobURL={blobURL}
                    />
                  </div>
                  <div className="interact_btn_center">
                    {recording == true && no_transcoding == false && (
                      <a
                        style={{cursor:"pointer"}}
                        className="keyboard_icon"
                        onClick={(e) => togglePlayer()}
                      >
                        <i id="playerIcon" class="playerIcon fas fa-pause"></i>
                      </a>
                    )}
                  </div> 
                  <div className="interact_btn_right">
                    {toggleDiv.isTopicOpen && (
                      <Topics
                        deviceDetails={{
                          deviceOrentation: style.orientation,
                          height: screenHeight,
                          width: screenWidth,
                        }}
                        detail={detail}
                        toggleTopicsDiv={toggleTopicsDiv}
                        questionVideo={questionVideo}
                        moduleQuestions={moduleQuestions}
                      />
                    )}
                    <a
                      onClick={() => {
                        isDivOpen((prev) => ({
                          ...prev,
                          isTopicOpen: !toggleDiv.isTopicOpen,
                        }));
                      }}
                      hover-tooltip="Click any question to hear answer."
                      style={{cursor:"pointer"}}
                      className="topic_btn"
                      id="topic_btn"
                    >
                      Topics
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="intract_voice_section">
        <div
          ref={(node) => {
            if (node &&(isMobile||isTablet)&&  style.orientation === "landscape") {
                const containerWidth = window.screen.height
               const attainWidth =  window.screen.height *16 /9
              if (attainWidth) {
                node.style.setProperty(
                  "max-width",
                  `${attainWidth}px`,
                  "important"
                );
              }
            }
          }}
        className="container">
          <div className="row">
            <div className="col-md-6 custom_six_col">
              <div className="begin_interact_title">
                To begin interacting by voice:
              </div>
              <ul className="interact_ul">
                <li>
                  <i class="fas fa-check-circle"></i>
                  <span>
                    Click on the microphone icon above to begin recording.
                  </span>
                </li>
                <li>
                  <i class="fas fa-check-circle"></i>
                  <span>
                    Speak your question into your device's microphone.
                  </span>
                </li>
                <li>
                  <i class="fas fa-check-circle"></i>
                  <span>
                    Click the microphone icon again to stop recording.
                  </span>
                </li>
                <li>
                  <i class="fas fa-check-circle"></i>
                  <span>Wait to hear an answer!</span>
                </li>
              </ul>
              <div className="begin_interact_title">
                To begin interacting by keyboard:
              </div>
              <ul className="interact_ul">
                <li>
                  <i class="fas fa-check-circle"></i>
                  <span>Click on the keyboard icon above.</span>
                </li>
                <li>
                  <i class="fas fa-check-circle"></i>
                  <span>Type in your question then hit “Ask Question.”</span>
                </li>
                <li>
                  <i class="fas fa-check-circle"></i>
                  <span>Wait to hear an answer!</span>
                </li>
              </ul>
            </div>
            <div className="col-md-6 custom_six_col">
              {/* <div className="title_interact_voice">
                Title: Practice Eternaview
              </div>
              <div className="row">
                <div className="col-md-6 custom_six_col">
                  <p className="record_title">Total Time Recorded:</p>
                  <p className="record_time">00:00:20</p>
                </div>
                <div className="col-md-6 custom_six_col">
                  <p className="record_title">Date Created:</p>
                  <p className="record_time">{moment(practice_detail.created_on).format("MM-DD-YYYY")}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 custom_six_col">
                  <p className="record_title"># Questions Answered:</p>
                  <p className="record_time">6</p>
                </div>
                <div className="col-md-6 custom_six_col">
                  <p className="record_title">Last Updated:</p>
                  <p className="record_time">{moment(practice_detail.created_on).format("MM-DD-YYYY")}</p>
                </div>
              </div> */}
              {/* <div className="row">
                <div className="col-md-6 custom_six_col">
                  <p className="record_title">Bio:</p>
                  <p className="record_title">No bio added!</p>
                </div>
              </div> */}
              <div className="row">
                <div className="col-md-12">
                  <div className="nootic_div">
                    <b>Note:</b> You can also just click on the questions in the
                    “Topics” drop-down menu if you prefer to interact that way.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        id="myModal201"
        className={
          firstTimePop == false
            ? "modal fade show show_custom_modal"
            : "modal fade show custom_modal"
        }
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close custom_close3"
                data-dismiss="modal"
                onClick={closePopUp}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p>
                Now that you've finished recording a few practice answers, you
                can try talking to yourself about the topics you just answered!
              </p>
              <p>
                (For the real thing, you would of course invite other viewers to
                interact with your recording)
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={closePopUp}>
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
            id="myModal201"
            className={
              maxQuery == true
                ? "modal fade show custom_modal"
                : "modal fade show show_custom_modal"
            }
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  {/* <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button> */}
                </div>
                <div className="modal-body">
                  <p>
                    Sorry, you have reached the maximum number of queries to
                    your practice Eternaview. We invite you to create a
                    full-length Eternaview if you would like to tell more about
                    your story and invite others to interact with it.
                  </p>
                </div>
                <div className="modal-footer">
                  <button type="button" onClick={closeQueryPopUp}>
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </div>
    </div>
  );
};

export default PracticeInteractF;
